/* TODO: cleanup this file in favor of Vault definitions when ready */
/* To disable lint warning, see: https://create-react-app.dev/docs/adding-css-reset */
@import-normalize;

@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';

/* Fonts Face rules*/

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-RegIta.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-ThinItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica New Text';
  src: url('./assets/fonts/HelveticaNowText-BlackItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Ivar Display Bold';
  src: url('./assets/fonts/IvarDisplay/ivardisplay-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/IvarDisplay/ivardisplay-bold-webfont.woff') format('woff'),
    url('./assets/fonts/IvarDisplay/ivardisplay-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* END Font Face rules*/

body,
#root {
  box-sizing: border-box;
  min-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* disable spinners to not cover part of input on mobile device */
@media (pointer: coarse) {
  ::-webkit-inner-spin-button {
    display: none;
  }
}

body {
  margin: 0;
  font-family: 'Helvetica New Text', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #121313;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
}
